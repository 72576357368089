import React from "react"
import { graphql } from "gatsby"
import useSiteTreeContext from "silverstripe-gatsby-helpers/lib/hooks/useSiteTreeContext"
import Layout from "../Page"
import SEOTags from "../../components/SEOTags"
import Breadcrumbs from "../../components/Breadcrumbs"
import Gallery from "@browniebroke/gatsby-image-gallery";
import '@browniebroke/gatsby-image-gallery/dist/style.css'

const Page = ({ data: { silverStripeDataObject }}) => {
	const { title, content } = silverStripeDataObject.SilverStripeSiteTree;
	const  {getChildren, isLevel}  = useSiteTreeContext();
	const children = getChildren();
	const isLevel2 = isLevel(2);
	const hasSubnav = isLevel(2) || !!children.length;

	const strippedContent = content.replace(/\[slideshow\]/, '');
	const galleryItems = silverStripeDataObject.SilverStripeBlogPost.ImageGalleryItems || [];

	const fullsize = galleryItems.map(item => item.NetwerkstattImageGalleryItem.Image.imageFile.full.fluid.src);
	const thumbs = galleryItems.map(item => item.NetwerkstattImageGalleryItem.Image.imageFile.thumb.fluid);
	
	return (
	  <Layout>
	    <SEOTags pageTitle={title} />

		<div className={`content ${hasSubnav ? 'hasSidebar' : ''}`}>
			<div className="main">
				{isLevel2 && 
					<Breadcrumbs />
				}
	    		<h1 className="font-bold text-2xl mb-4">{title}</h1>
	    		<div className="font-serif htmltext" dangerouslySetInnerHTML={{__html: strippedContent}} />

				{galleryItems && <Gallery images={fullsize} thumbs={thumbs} />}
			</div>
		</div>
	  </Layout>
  	);
};

export default Page

export const pageQuery = graphql`
	query ($link: String!) {
		silverStripeDataObject(link: {eq: $link}) {
			SilverStripeSiteTree {
				title
				content
			}
			SilverStripeBlogPost {
				ImageGalleryItems {
					NetwerkstattImageGalleryItem {
						caption
						sortOrder
						Image {
							imageFile {
								thumb: childImageSharp {
									fluid(maxWidth: 270, maxHeight: 270) {
										...GatsbyImageSharpFluid
									}
								}
								full: childImageSharp {
									fluid(
										maxWidth: 1024
										quality: 85
										srcSetBreakpoints: [576, 768, 992, 1200]
									) {
										...GatsbyImageSharpFluid
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;